import React, { useState, useRef, useEffect } from 'react';
import { Menu } from 'primereact/menu';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import "../style/Navbar.css"
import { PrimeIcons } from 'primereact/api';
import { Button } from 'react-bootstrap';

const Sidebar = (props) => {
    const { i18n, t } = useTranslation();
    const [hidden, setHidden] = useState(false)
    useEffect(() => {
        setHidden(!props.visible);
    }, [props.visible])

    const menu = useRef(null)
    return (
        <>
            <Menu model={[
                {

                    label: t("sidebar"), items: [
                        { label: t("home"), icon: PrimeIcons.HOME },
                        {
                            label: t("products"), icon: PrimeIcons.SHOPPING_CART, style: {}, command: (e) => {
                                menu.current.toggle(e.originalEvent)
                            }
                        },
                        { label: t("about"), icon: PrimeIcons.INFO_CIRCLE, url: "/about" },
                        { label: t("contact"), icon: PrimeIcons.PHONE, url: "/contact" }]
                }
            ]} hidden={hidden} className='mobileBar' />

            <Menu ref={menu} style={{
                alignContent: "center",
                display: "flex",
                backgroundColor: "rgb(242, 107, 58)",
                border: 0

            }} model={[
                {
                    label: t("products"), style: { color: "white" }, items: [
                        { label: "Feldspat", url: "/feldspat", },
                        { label: "Mix Feldspat", url: "/mixfeldspat", },
                        { label: t("quartz"), url: "/kuvars" }
                    ]
                }
            ]} popup hidden={false} />

        </>
    );
};
export default Sidebar;