import React from "react";
import Navbar from '../navBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/md-dark-indigo/theme.css";
import "primereact/resources/primereact.min.css"
import 'primeicons/primeicons.css';
import 'react-slideshow-image/dist/styles.css'
import { useTranslation } from "react-i18next";
import FooterComponent from "../footer";
import "../../style/table.css"
import TableFeldspat from "../feldspatTable";
import { Menu } from "primereact/menu";
import FeldspatMenu from "../feldspatMenu";
import "../../style/commonMobile.css";

const FeldspatFK7602 = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <PrimeReactProvider>
            <Navbar />
            <div className="feldspatContainer" >
                <div style={{ display: "block" }}>
                    <FeldspatMenu />
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                    <TableFeldspat baslik="TEKNOMADEN FELDSPAR (-10MM) FK7602 TDS" plantAddress="Yeşilköy/Kavaklıdere/Muğla" kod="FK7602" humidity="3" oilAbsorb="" na2o="6.10" k2o="0.79" cao="0.34" fe2o3="0.60" tio2="0.20" al2o3="14.54" mgo="0.45" p2o5="0.00" sio2="76.65" kk="0.33" l="68,41" a="2,86" b="14,28" albit="52.5" ortoklas="4.5" anortit="1.6" kaolinit="7.8" kuvars="34.2" />
                </div>
            </div>
            <FooterComponent />
        </PrimeReactProvider>
    );
}
export default FeldspatFK7602;