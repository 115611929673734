import React, { useEffect, useState } from "react";
import "../style/footerStyle.css"
import { PrimeIcons } from "primereact/api";
const FooterComponent = () => {

    const [year, setYear] = useState(2023);

    useEffect(() => {
        const now = new Date();
        setYear(now.getFullYear());
    }, [])

    return (
        <footer>
            <div className="footerContainer" >
                <iframe className="map" title="map" width={300} height={200} src="https://maps.google.com/maps?width=50%25&amp;height=300&amp;hl=en&amp;q=%22Atat%C3%BCrk%20Mahallesi%20Dr.%20Hac%C4%B1%20%C4%B0brahim%20Bodur%20Caddesi%20No%2034/1%20%C3%87an%20-%20%C3%87anakkale%22+(Konum)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                <div style={{ justifyContent: "flex-start", textAlign: "left", marginLeft: 15 }}>
                    <div style={{ flexDirection: "row", display: "flex", alignItems: "center", gap: 10 }}>
                        <i className={PrimeIcons.PHONE} style={{ fontSize: "1rem" }} />
                        <p>+90 286 416 54 41</p>
                    </div>

                    <div style={{ flexDirection: "row", display: "flex", alignItems: "center", gap: 10 }}>
                        <i className={PrimeIcons.ENVELOPE} style={{ fontSize: "1rem" }} />
                        <p>info@teknomaden.com.tr</p>
                    </div>

                    <div onClick={() => { window.open('https://maps.google.com/maps?q="Atatürk Mahallesi Dr. Hacı İbrahim Bodur Caddesi No 34/1 Çan - Çanakkale"') }} style={{ flexDirection: "row", display: "flex", alignItems: "center", gap: 10 }}>
                        <i className={PrimeIcons.MAP_MARKER} style={{ fontSize: "1rem" }} />
                        <p>Atatürk Mahallesi Dr. Hacı İbrahim Bodur Caddesi No 34/1 Çan - Çanakkale</p>
                    </div>

                    <div onClick={() => { window.open('https://maps.google.com/maps?q="Kurucuova Mahallesi Mersinli Su Mevki No: 4 Kavaklıdere / Muğla"') }} style={{ flexDirection: "row", display: "flex", alignItems: "center", gap: 10 }}>
                        <i className={PrimeIcons.MAP_MARKER} style={{ fontSize: "1rem" }} />
                        <p>Kurucuova Mahallesi Mersinli Su Mevki No: 4 Kavaklıdere / Muğla</p>
                    </div>
                    <p style={{ color: "#aaa", fontWeight: "normal", fontStyle: "italic", fontSize: "0.85rem" }}>Copyright Teknomaden © {year}</p>

                </div>
            </div>

        </footer>
    )
}

export default FooterComponent;