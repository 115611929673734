import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AboutPage from "./components/aboutPage"
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ContactUs from './components/contactUs';
import Products from './components/products';
import Feldspat from './components/feldspat';
import FeldspatFK7502 from './components/feldspatSayfalari/fk7502';
import FeldspatFK6103 from './components/feldspatSayfalari/fk6103';
import FeldspatFK7602 from './components/feldspatSayfalari/fk7602';
import FeldspatKF7201 from './components/feldspatSayfalari/kf7201';
import FeldspatKF8101 from './components/feldspatSayfalari/kf8101';
import FeldspatKF8101_2 from './components/feldspatSayfalari/kf8101_2';
import FeldspatKF8203 from './components/feldspatSayfalari/kf8203';
import FeldspatKF9101 from './components/feldspatSayfalari/kf9101';
import FeldspatKF9201 from './components/feldspatSayfalari/kf9201';
import FeldspatKF9203 from './components/feldspatSayfalari/kf9203';
import FeldspatKF7102 from './components/feldspatSayfalari/kf7102';
import FeldspatKMEX8101 from './components/feldspatSayfalari/kmex8101';
import FeldspatKMEX7101 from './components/feldspatSayfalari/kmex7101';
import MixFeldspatK8000 from './components/mixFeldspatSayfalari/mixFeldspat_k8000';
import MixFeldspatK8902 from './components/mixFeldspatSayfalari/mixFeldspat_k8902';
import MixFeldspatK8201 from './components/mixFeldspatSayfalari/mixFeldspat_k8201';
import Quartz_100_300 from './components/kuvarsSayfalari/kuvars_100_300';
import Quartz_Raw from './components/kuvarsSayfalari/kuvars_raw';
import Quartz_200_500 from './components/kuvarsSayfalari/kuvars_200_500';
import Quartz_300_700 from './components/kuvarsSayfalari/kuvars_300_700';
import Quartz_700_1200 from './components/kuvarsSayfalari/kuvars_700_1200';
import Quartz_100_800 from './components/kuvarsSayfalari/kuvars_100_800';
import Quartz_Powder_100 from './components/kuvarsSayfalari/kuvars_powder_100';
import Quartz_Powder_75 from './components/kuvarsSayfalari/kuvars_powder_75';
import Quartz_Powder_63 from './components/kuvarsSayfalari/kuvars_powder_63';
import Quartz_Powder_45 from './components/kuvarsSayfalari/kuvars_powder_45';
import Quartz_Sand_98KK680 from './components/kuvarsSayfalari/kuvars_sand_98kk680';
import Quartz_Sand_99KK680 from './components/kuvarsSayfalari/kuvars_sand_99kk680';
const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/products",
    element: <Products />
  },
  {
    path: "feldspat",
    element: <Feldspat />
  },
  {
    path: "/about",
    element: <AboutPage />
  },
  {
    path: "/contact",
    element: <ContactUs />
  },
  {
    path: "/feldspat/fk7502",
    element: <FeldspatFK7502 />
  },
  {
    path: "/feldspat/fk7602",
    element: <FeldspatFK7602 />
  },
  {
    path: "/feldspat/fk6103",
    element: <FeldspatFK6103 />
  },
  //KFLER
  {
    path: "/feldspat/kf7102",
    element: <FeldspatKF7102 />
  },
  {
    path: "/feldspat/kmex7101",
    element: <FeldspatKMEX7101 />
  },
  {
    path: "/feldspat/kmex8101",
    element: <FeldspatKMEX8101 />
  },
  {
    path: "/feldspat/kf7201",
    element: <FeldspatKF7201 />
  },
  {
    path: "/feldspat/kf8101",
    element: <FeldspatKF8101 />
  },
  {
    path: "/feldspat/kf8101_2",
    element: <FeldspatKF8101_2 />
  },
  {
    path: "/feldspat/kf8203",
    element: <FeldspatKF8203 />
  },
  {
    path: "/feldspat/kf9101",
    element: <FeldspatKF9101 />
  },
  {
    path: "/feldspat/kf9201",
    element: <FeldspatKF9201 />
  },
  {
    path: "/feldspat/kf9203",
    element: <FeldspatKF9203 />
  },
  {
    path: "/mixfeldspat",
    element: <MixFeldspatK8000 />
  },
  {
    path: "/mixfeldspat/98k8902",
    element: <MixFeldspatK8902 />
  },
  {
    path: "/mixfeldspat/98k8201",
    element: <MixFeldspatK8201 />
  },
  {
    path: "/kuvars",
    element: <Quartz_Raw />
  },
  {
    path: "/kuvars/100_300",
    element: <Quartz_100_300 />
  },
  {
    path: "/kuvars/200_500",
    element: <Quartz_200_500 />
  },
  {
    path: "/kuvars/300_700",
    element: <Quartz_300_700 />
  },
  {
    path: "/kuvars/700_1200",
    element: <Quartz_700_1200 />
  },
  {
    path: "/kuvars/100_800",
    element: <Quartz_100_800 />
  },
  {
    path: "/kuvars/powder_100",
    element: <Quartz_Powder_100 />
  },
  {
    path: "/kuvars/powder_75",
    element: <Quartz_Powder_75 />
  },
  {
    path: "/kuvars/powder_63",
    element: <Quartz_Powder_63 />
  },
  {
    path: "/kuvars/powder_45",
    element: <Quartz_Powder_45 />
  },
  {
    path: "/kuvars/98kk680",
    element: <Quartz_Sand_98KK680 />
  },

  {
    path: "/kuvars/99kk680",
    element: <Quartz_Sand_99KK680 />
  }




]);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
