import React from "react";
import Navbar from '../navBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/md-dark-indigo/theme.css";
import "primereact/resources/primereact.min.css"
import 'primeicons/primeicons.css';
import 'react-slideshow-image/dist/styles.css'
import { useTranslation } from "react-i18next";
import FooterComponent from "../footer";
import "../../style/table.css"
import TableFeldspat from "../feldspatTable";
import { Menu } from "primereact/menu";
import FeldspatMenu from "../feldspatMenu";
import "../../style/commonMobile.css";

const FeldspatFK7502 = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <PrimeReactProvider>
            <Navbar />
            <div className="feldspatContainer" >
                <div style={{ display: "block" }}>
                    <FeldspatMenu />
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                    <TableFeldspat baslik="TEKNOMADEN FELDSPAR (-10MM) FK7502 TDS" plantAddress="Yeşilköy/Kavaklıdere/Muğla" kod="FK7502" humidity="3" oilAbsorb="" na2o="6.50" k2o="0.62" cao="0.32" fe2o3="0.50" tio2="0.18" al2o3="14.74" mgo="0.10" p2o5="0.00" sio2="76.71" kk="0.33" l="70,41" a="1,90" b="14,28" albit="55.0" ortoklas="3.7" anortit="1.6" kaolinit="7.1" kuvars="32.7" />
                </div>
            </div>
            <FooterComponent />
        </PrimeReactProvider>
    );
}
export default FeldspatFK7502;