import React, { useLayoutEffect, useRef, useState, useSyncExternalStore } from "react"
import LanguageSelector from "./languageSelector";
import Logo from "../images/teknomaden_logo.png"
import NavBarSection from "./navBarSection";
import Sidebar from "./sideBar";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { PrimeIcons } from "primereact/api";

import ProductButton from "./productButton";

import "../style/Navbar.css"
const Navbar = () => {

    const { t, i18n } = useTranslation()
    const [menuVisible, setMenuVisible] = useState(false);
    return (
        <>
            <nav className="nav" style={{ display: "grid" }} >
                <Button icon={PrimeIcons.BARS} className='menuButton' onClick={() => setMenuVisible(!menuVisible)} />
                <img onClick={() => { window.location.href = "/" }} src={Logo} style={{ width: 150, padding: 10, height: "auto", paddingTop: 12, paddingBottom: 12, cursor: "pointer" }} alt="Teknomaden" />

                <div className="sections" style={{ flexDirection: "row", gap: 10, justifyContent: "center" }}>
                    <NavBarSection name={t('home')} href="/" />
                    {/*}<NavBarSection name={t('products')} href="/products" />{*/}
                    <NavBarSection name={t('about')} href="/about" />
                    <ProductButton />

                    <NavBarSection name={t('contact')} href="/contact" />
                </div>

                <LanguageSelector />
            </nav >
            <Sidebar visible={menuVisible} />

        </>
    )
}

export default Navbar;