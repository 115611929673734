import React from "react";

const NavBarSection = (props) => {
    return (
        <div onClick={() => props.href ? window.location.href = props.href : null}
            style={{
                display: "flex",
                background: "rgb(242, 107, 58)",
                cursor: "pointer",
                width: 160,
                height: 70,
                borderRadius: 3,
                minWidth: 50,
                maxWidth: 160,
                justifyContent: "center",
                alignItems: "center",

            }}>
            <p style={{ fontSize: "1rem", overflow: "hidden", margin: 0, color: "white", fontWeight: "bold" }}>{props.name}</p>
        </div>
    )
}

export default NavBarSection;