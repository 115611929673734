import React from "react"; import Navbar from './navBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/md-dark-indigo/theme.css";
import "primereact/resources/primereact.min.css"
import 'primeicons/primeicons.css';
import 'react-slideshow-image/dist/styles.css'
import "../style/contactUs.css";
import { useTranslation } from "react-i18next";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import FooterComponent from "./footer";

const Products = () => {
    const { t, i18n } = useTranslation();
    return (
        <PrimeReactProvider>
            <Navbar />
            <h1>{t("products")}</h1>

            <FooterComponent />
        </PrimeReactProvider>
    );
}
export default Products;