import React from "react";
import Navbar from '../navBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/md-dark-indigo/theme.css";
import "primereact/resources/primereact.min.css"
import 'primeicons/primeicons.css';
import 'react-slideshow-image/dist/styles.css'
import { useTranslation } from "react-i18next";
import FooterComponent from "../footer";
import "../../style/table.css"
import { Menu } from "primereact/menu";
import "../../style/commonMobile.css";
import QuartzMenu from "../quartzMenu";
import TableSandQuartz from "../sandQuartzTable";

const Quartz_Sand_99KK680 = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <PrimeReactProvider>
            <Navbar />
            <div className="feldspatContainer">
                <div style={{ display: "block" }}>
                    <QuartzMenu />
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                    <TableSandQuartz baslik="TEKNOMADEN SAND QUARTZ TDS" plantAddress="Yeşilköy/Kavaklıdere/Muğla" kod="99KK680 (-400+100 MİCRON)" humidity="0.3" na2o="4.30" k2o="0.14" cao="0.01" fe2o3="0.03" tio2="0.06" al2o3="5.25" mgo="0.06" p2o5="0.17" sio2="89.68" kk="0.30" l="82.93" a="1.23" b="8.62" />
                </div>
            </div>
            <FooterComponent />
        </PrimeReactProvider>
    );
}
export default Quartz_Sand_99KK680;