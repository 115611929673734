import './App.css';
import Navbar from './components/navBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PrimeReactProvider } from 'primereact/api';
import 'primeicons/primeicons.css';
import 'react-slideshow-image/dist/styles.css'
import './style/mainPage.css'

import { PrimeIcons } from "primereact/api";

import SlideShow from './components/slideShow';
import SectorCard from './components/sectorCard';

import FooterComponent from './components/footer';
import i18n from './i18n';
import { useTranslation } from 'react-i18next';

const App = () => {
  const { t, i18n } = useTranslation()

  return (
    <PrimeReactProvider>
      <div className="App">
        <Navbar />
        <SlideShow />
        <hr />
        <h1 style={{ fontWeight: "500", fontStyle: "italic" }}>{t("sectors")}</h1>
        <div className='gridHolder' style={{ alignItems: "center", justifyContent: "center", display: "flex", padding: 12, borderRadius: 12, border: "1px solid #ccc", margin: 12, background: "#eee" }}>
          <div className='sectorsGrid' style={{ display: "grid", alignItems: "center", justifyContent: "space-evenly", gap: 25, flexDirection: "row" }}>
            <SectorCard heading={t("cam")} icon={PrimeIcons.INFO_CIRCLE} />
            <SectorCard heading={t("seramik")} icon={PrimeIcons.INFO_CIRCLE} />
            <SectorCard heading={t("porselen")} icon={PrimeIcons.INFO_CIRCLE} />
            <SectorCard heading={t("kompozeTas")} icon={PrimeIcons.INFO_CIRCLE} />
            <SectorCard heading={t("boyaSanayi")} icon={PrimeIcons.INFO_CIRCLE} />
            <SectorCard heading={t("vitrifiye")} icon={PrimeIcons.INFO_CIRCLE} />
          </div>
        </div>

        <h1 style={{ fontWeight: "bold" }}>{t('about')}</h1>
        <div className="aboutBackground">
          <p>{t("aboutContent")}</p>
        </div>
        <FooterComponent />
      </div>
    </PrimeReactProvider>
  );
}

export default App;
