import React, { useState } from "react";
import i18n from '../i18n';
import Turkey from "../images/tr_circle.webp"
import USA from "../images/uk_circle.webp"
const LanguageSelector = () => {

    const [_, setSelectedLanguage] = useState(i18n.language); // i18n.language contains the language assigned to lng in i18n.js file.

    const chooseLanguage = (lang) => {
        i18n.changeLanguage(lang);   // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
        setSelectedLanguage(lang);
    }

    return (
        <div className="langSelect" style={{ float: "right", display: "flex", justifySelf: "flex-end", alignItems: "center", gap: 5 }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <img onClick={() => chooseLanguage("tr")} style={{ cursor: "pointer", borderRadius: 360, backgroundColor: "rgb(208,47,40)", }} height={40} width={40} src={Turkey} alt="Türkçe" />
                <p style={{ color: "white", margin: 0, fontWeight: "bold" }}>Türkçe</p>
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <img onClick={() => chooseLanguage("en")} style={{ cursor: "pointer" }} height={40} src={USA} alt="English" />
                <p style={{ color: "white", margin: 0, fontWeight: "bold" }}>English</p>

            </div>
        </div>
    );
};

export default LanguageSelector;