import React from "react";
import Navbar from '../navBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/md-dark-indigo/theme.css";
import "primereact/resources/primereact.min.css"
import 'primeicons/primeicons.css';
import 'react-slideshow-image/dist/styles.css'
import { useTranslation } from "react-i18next";
import FooterComponent from "../footer";
import "../../style/table.css"
import { Menu } from "primereact/menu";
import "../../style/commonMobile.css";
import QuartzMenu from "../quartzMenu";
import TableSandQuartz from "../sandQuartzTable";

const Quartz_Sand_98KK680 = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <PrimeReactProvider>
            <Navbar />
            <div className="feldspatContainer">
                <div style={{ display: "block" }}>
                    <QuartzMenu />
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                    <TableSandQuartz baslik="TEKNOMADEN SAND QUARTZ TDS" plantAddress="Yeşilköy/Kavaklıdere/Muğla" kod="98KK680 (-400+100 MİCRON)" humidity="0.3" na2o="3.85" k2o="0.62" cao="0.10" fe2o3="0.03" tio2="0.04" al2o3="4.72" mgo="0.08" p2o5="0.19" sio2="90.07" kk="0.30" l="84.74" a="0.99" b="7.45" />
                </div>
            </div>
            <FooterComponent />
        </PrimeReactProvider>
    );
}
export default Quartz_Sand_98KK680;