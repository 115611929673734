import React from "react";
import Navbar from '../navBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/md-dark-indigo/theme.css";
import "primereact/resources/primereact.min.css"
import 'primeicons/primeicons.css';
import 'react-slideshow-image/dist/styles.css'
import { useTranslation } from "react-i18next";
import FooterComponent from "../footer";
import "../../style/table.css"
import TableFeldspat from "../feldspatTable";
import { Menu } from "primereact/menu";
import MixFeldspatMenu from "../mixFeldspatMenu"
import "../../style/commonMobile.css";

const MixFeldspatK8902 = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <PrimeReactProvider>
            <Navbar />
            <div className="feldspatContainer">
                <div style={{ display: "block" }}>
                    <MixFeldspatMenu />
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                    <TableFeldspat baslik="TEKNOMADEN MIX FELDSPAR (-10MM) 98K8902 TDS" plantAddress="Yeşilköy/Kavaklıdere/Muğla" kod="98K8902" humidity="3" oilAbsorb="" na2o="5.47" k2o="2.23" cao="0.32" fe2o3="0.89" tio2="0.16" al2o3="15.04" mgo="0.30" p2o5="0.11" sio2="75.17" kk="0.33" l="76,05" a="1,89" b="10,85" albit="45.90" ortoklas="13.20" anortit="1.60" kaolinit="7.88" kuvars="31.50" />
                </div>
            </div>
            <FooterComponent />
        </PrimeReactProvider>
    );
}
export default MixFeldspatK8902;