import React from "react";
import { Menu } from "primereact/menu";

const QuartzMenu = props => {
    return (
        <>
            <Menu style={{ borderRadius: 0, background: "white", width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }} model={[
                {
                    label: "RAW QUARTZ", style: { color: "black", fontWeight: "900", fontSize: "1rem", alignItems: "flex-start" }, items: [
                        { label: <b style={{ color: "black" }}>TEKNOMADEN RAW QUARTZ TDS</b>, url: "/kuvars", },
                        { separator: true, style: { height: 1, background: "black", width: "100%" } },
                    ]
                }
            ]} hidden={false} />
            <Menu style={{ borderRadius: 0, background: "white", width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }} model={[
                {
                    label: "MICRONIZED QUARTZ", style: { color: "black", fontWeight: "900", fontSize: "1rem", alignItems: "flex-start" }, items: [
                        { label: <b style={{ color: "black" }}>TEKNOMADEN MICRONIZED QUARTZ (100-300 micron) TDS</b>, url: "/kuvars/100_300", },
                        { separator: true, style: { height: 1, background: "black", width: "100%" } },
                        { label: <b style={{ color: "black" }}>TEKNOMADEN MICRONIZED QUARTZ (200-500 micron) TDS</b>, url: "/kuvars/200_500", },
                        { separator: true, style: { height: 1, background: "black", width: "100%" } },
                        { label: <b style={{ color: "black" }}>TEKNOMADEN MICRONIZED QUARTZ (300-700 micron) TDS</b>, url: "/kuvars/300_700", },
                        { separator: true, style: { height: 1, background: "black", width: "100%" } },
                        { label: <b style={{ color: "black" }}>TEKNOMADEN MICRONIZED QUARTZ (700-1200 micron) TDS</b>, url: "/kuvars/700_1200", },
                        { separator: true, style: { height: 1, background: "black", width: "100%" } },
                        { label: <b style={{ color: "black" }}>TEKNOMADEN MICRONIZED QUARTZ (100-800 micron) TDS</b>, url: "/kuvars/100_800", },
                        { separator: true, style: { height: 1, background: "black", width: "100%" } },

                        { label: <b style={{ color: "black" }}>TEKNOMADEN POWDER QUARTZ (-100 micron) TDS</b>, url: "/kuvars/powder_100", },
                        { separator: true, style: { height: 1, background: "black", width: "100%" } },
                        { label: <b style={{ color: "black" }}>TEKNOMADEN POWDER QUARTZ (-75 micron) TDS</b>, url: "/kuvars/powder_75", },
                        { separator: true, style: { height: 1, background: "black", width: "100%" } },
                        { label: <b style={{ color: "black" }}>TEKNOMADEN POWDER QUARTZ (-63 micron) TDS</b>, url: "/kuvars/powder_63", },
                        { separator: true, style: { height: 1, background: "black", width: "100%" } },
                        { label: <b style={{ color: "black" }}>TEKNOMADEN POWDER QUARTZ (-45 micron) TDS</b>, url: "/kuvars/powder_45", },
                        { separator: true, style: { height: 1, background: "black", width: "100%" } },

                    ]
                }
            ]} hidden={false} />
            <Menu style={{ borderRadius: 0, background: "white", width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }} model={[
                {
                    label: "SAND QUARTZ", style: { color: "black", fontWeight: "900", fontSize: "1rem", alignItems: "flex-start" }, items: [
                        { label: <b style={{ color: "black" }}>TEKNOMADEN SAND QUARTZ (-400+100 micron) 99KK680 TDS</b>, url: "/kuvars/99kk680", },
                        { separator: true, style: { height: 1, background: "black", width: "100%" } },
                        { label: <b style={{ color: "black" }}>TEKNOMADEN SAND QUARTZ (-400+100 micron) 98KK680 TDS</b>, url: "/kuvars/98kk680", },
                        { separator: true, style: { height: 1, background: "black", width: "100%" } },
                    ]
                }
            ]} hidden={false} />
        </>
    )
}

export default QuartzMenu;