import React from "react";
import Navbar from '../navBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/md-dark-indigo/theme.css";
import "primereact/resources/primereact.min.css"
import 'primeicons/primeicons.css';
import 'react-slideshow-image/dist/styles.css'
import { useTranslation } from "react-i18next";
import FooterComponent from "../footer";
import "../../style/table.css"
import TableFeldspat from "../feldspatTable";
import { Menu } from "primereact/menu";
import MixFeldspatMenu from "../mixFeldspatMenu"
import "../../style/commonMobile.css";

const MixFeldspatK8201 = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <PrimeReactProvider>
            <Navbar />
            <div className="feldspatContainer">
                <div style={{ display: "block" }}>
                    <MixFeldspatMenu />
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                    <TableFeldspat baslik="TEKNOMADEN MIX FELDSPAR (-10MM) 98K8201 TDS" plantAddress="Yeşilköy/Kavaklıdere/Muğla" kod="98K8201" humidity="0.3" oilAbsorb="" na2o="5.50" k2o="3.21" cao="0.26" fe2o3="0.18" tio2="0.07" al2o3="17.80" mgo="0.11" p2o5="0.24" sio2="72.57" kk="0.30" l="84.77" a="-0.26" b="6.25" albit="44.50" ortoklas="19.00" anortit="1.30" kaolinit="13.11" kuvars="22.11" />
                </div>
            </div>
            <FooterComponent />
        </PrimeReactProvider>
    );
}
export default MixFeldspatK8201;