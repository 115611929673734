import React from "react";
import Navbar from '../navBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/md-dark-indigo/theme.css";
import "primereact/resources/primereact.min.css"
import 'primeicons/primeicons.css';
import 'react-slideshow-image/dist/styles.css'
import { useTranslation } from "react-i18next";
import FooterComponent from "../footer";
import "../../style/table.css"
import TableFeldspat from "../feldspatTable";
import { Menu } from "primereact/menu";
import FeldspatMenu from "../feldspatMenu";
import "../../style/commonMobile.css";

const FeldspatKMEX8101 = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <PrimeReactProvider>
            <Navbar />
            <div className="feldspatContainer">
                <div style={{ display: "block" }}>
                    <FeldspatMenu />
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                    <TableFeldspat baslik="TEKNOMADEN FELDSPAR (-212 MICRON) 99KMEX-KF8101 TDS" plantAddress="Yeşilköy/Kavaklıdere/Muğla" kod="99KMEX-KF8101" humidity="0.3" oilAbsorb="" na2o="8.14" k2o="0.22" cao="0.27" fe2o3="0.03" tio2="0.07" al2o3="15.08" mgo="0.10" p2o5="0.00" sio2="75.61" kk="0.43" l="91.00" a="-0.29" b="6.37" albit="68.9" ortoklas="1.3" anortit="1.3" kaolinit="4.2" kuvars="24.3" />
                </div>
            </div>
            <FooterComponent />
        </PrimeReactProvider>
    );
}
export default FeldspatKMEX8101;