import React from "react"
import { useTranslation } from "react-i18next";

const TableRawQuartz = props => {
    const { t, i18n } = useTranslation();
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%" }}>
            <h4 style={{ borderBottomWidth: 1, borderBottomColor: "black", borderBottomStyle: "solid" }}>{props.baslik}</h4>
            <h3>{props.kod}</h3>
            <p>PHYSICAL & CHEMICAL PROPERTIES OF THE MATERIAL</p>
            <div>


                <table cellPadding={0} cellSpacing={0} style={{ width: '100%', borderWidth: 1, borderColor: "black", margin: 12, borderStyle: "solid" }}>
                    <tbody>
                        <tr>
                            <td style={{ textAlign: 'justify' }}><strong>SiO2</strong></td>
                            <td style={{ textAlign: 'justify' }}><strong>K2O </strong></td>
                            <td style={{ textAlign: 'justify' }}><strong>Na2O </strong></td>
                            <td style={{ textAlign: 'justify' }}><strong>TiO2 </strong></td>
                            <td style={{ textAlign: 'justify' }}><strong>Fe2O3 </strong></td>
                            <td style={{ textAlign: 'justify' }}><strong>Al2O3 </strong></td>
                            <td style={{ textAlign: 'justify' }}><strong>MgO </strong></td>
                            <td style={{ textAlign: 'justify' }}><strong>CaO </strong></td>
                            <td style={{ textAlign: 'justify' }}><strong>K.K</strong></td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'center', }}><strong>{props.sio2} </strong></td>
                            <td style={{ textAlign: 'center', }}><strong>{props.k2o} </strong></td>
                            <td style={{ textAlign: 'center', }}><strong>{props.na2o} </strong></td>
                            <td style={{ textAlign: 'center', }}><strong>{props.tio2} </strong></td>
                            <td style={{ textAlign: 'center', }}><strong>{props.fe2o3} </strong></td>
                            <td style={{ textAlign: 'center', }}><strong>{props.al2o3} </strong></td>
                            <td style={{ textAlign: 'center', }}><strong>{props.mgo} </strong></td>
                            <td style={{ textAlign: 'center', }}><strong>{props.cao} </strong></td>
                            <td style={{ textAlign: 'center', }}><strong>{props.kk}</strong></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}
export default TableRawQuartz;