import React from "react";
import Navbar from '../navBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/md-dark-indigo/theme.css";
import "primereact/resources/primereact.min.css"
import 'primeicons/primeicons.css';
import 'react-slideshow-image/dist/styles.css'
import { useTranslation } from "react-i18next";
import FooterComponent from "../footer";
import "../../style/table.css"
import { Menu } from "primereact/menu";
import "../../style/commonMobile.css";
import TableQuartz from "../quartzTable";
import QuartzMenu from "../quartzMenu";
import TableFeldspat from "../feldspatTable";

const Quartz_Powder_75 = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <PrimeReactProvider>
            <Navbar />
            <div className="feldspatContainer">
                <div style={{ display: "block" }}>
                    <QuartzMenu />
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                    <TableQuartz baslik="TEKNOMADEN POWDER QUARTZ TDS" plantAddress="Yeşilköy/Kavaklıdere/Muğla" kod="-75 micron" humidity="0.3" sio2="98.57" k2o="0.16" na2o="0.30" tio2="0.04" fe2o3="0.05" al2o3="0.50" mgo="0.03" cao="0.0001" kk="0.35" l="87.16" a="0.09" b="1.43" />
                </div>
            </div>
            <FooterComponent />
        </PrimeReactProvider>
    );
}
export default Quartz_Powder_75;