import React from "react"


const SectorCard = props => {
    return (
        <div style={{ height: 200, width: 200, display: "flex", flexDirection: "column", alignItems: "center", gap: 25, borderRadius: 24, background: "#fafafa", border: "1px dashed #aaa", padding: 15, justifyContent: "center" }}>
            <h3>{props.heading}</h3>
        </div>
    )
}

export default SectorCard;