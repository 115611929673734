import React, { useState, useRef, useEffect } from 'react';
import { Menu } from 'primereact/menu';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'react-bootstrap';

const ProductButton = props => {
    const { i18n, t } = useTranslation();
    const menu = useRef(null);

    return (
        <div style={{ display: "flex", alignContent: "center", width: 160, justifyItems: "center" }} >
            <button style={{
                display: "flex",
                background: "rgb(242, 107, 58)",
                cursor: "pointer",
                height: 70,
                borderRadius: 3,
                minWidth: 50,
                width: 160,
                border: 0,
                fontWeight: "bold",
                justifyContent: "center",
                alignItems: "center",
            }} onClick={e => menu.current.toggle(e)}>{t("products")}</button>
            <Menu ref={menu} style={{
                alignContent: "center",
                display: "flex",
                backgroundColor: "rgb(242, 107, 58)",
                border: 0

            }} model={[
                {
                    label: t("products"), style: { color: "white" }, items: [
                        { label: "Feldspat", url: "/feldspat", },
                        { label: "Mix Feldspat", url: "/mixfeldspat", },
                        { label: t("quartz"), url: "/kuvars" }
                    ]
                }
            ]} popup hidden={false} />
        </div>
    );
};
export default ProductButton;