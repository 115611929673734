import React from "react";
import Navbar from '../navBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/md-dark-indigo/theme.css";
import "primereact/resources/primereact.min.css"
import 'primeicons/primeicons.css';
import 'react-slideshow-image/dist/styles.css'
import { useTranslation } from "react-i18next";
import FooterComponent from "../footer";
import "../../style/table.css"
import TableFeldspat from "../feldspatTable";
import { Menu } from "primereact/menu";
import FeldspatMenu from "../feldspatMenu";
import "../../style/commonMobile.css";

const FeldspatKF7201 = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <PrimeReactProvider>
            <Navbar />
            <div className="feldspatContainer">
                <div style={{ display: "block" }}>
                    <FeldspatMenu />
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                    <TableFeldspat baslik="TEKNOMADEN FELDSPAR (-212+63 MICRON) 99KF7201 TDS" plantAddress="Yeşilköy/Kavaklıdere/Muğla" kod="99KF7201" humidity="0.3" oilAbsorb="" na2o="6.87" k2o="0.35" cao="0.19" fe2o3="0.17" tio2="0.06" al2o3="12.28" mgo="0.10" p2o5="0.20" sio2="79.8" kk="0.30" l="83.85" a="0.14" b="8.58" albit="58.1" ortoklas="2.1" anortit="0.9" kaolinit="0.6" kuvars="38.2" />
                </div>
            </div>
            <FooterComponent />
        </PrimeReactProvider>
    );
}
export default FeldspatKF7201;