import React from "react";
import Navbar from '../navBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/md-dark-indigo/theme.css";
import "primereact/resources/primereact.min.css"
import 'primeicons/primeicons.css';
import 'react-slideshow-image/dist/styles.css'
import { useTranslation } from "react-i18next";
import FooterComponent from "../footer";
import "../../style/table.css"
import TableFeldspat from "../feldspatTable";
import { Menu } from "primereact/menu";
import FeldspatMenu from "../feldspatMenu";
import "../../style/commonMobile.css";

const FeldspatKF8101_2 = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <PrimeReactProvider>
            <Navbar />
            <div className="feldspatContainer">
                <div style={{ display: "block" }}>
                    <FeldspatMenu />
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                    <TableFeldspat baslik="TEKNOMADEN FELDSPAR (-212+63 MICRON) 98KF8101 TDS" plantAddress="Yeşilköy/Kavaklıdere/Muğla" kod="98KF8101" humidity="0.3" oilAbsorb="" na2o="7.41" k2o="0.25" cao="0.51" fe2o3="0.11" tio2="0.09" al2o3="12.25" mgo="0.13" p2o5="0.24" sio2="79.95" kk="0.30" l="84.07" a="0.98" b="7.53" albit="62.7" ortoklas="1.5" anortit="2.5" kaolinit="0.00" kuvars="33.3" />
                </div>
            </div>
            <FooterComponent />
        </PrimeReactProvider>
    );
}
export default FeldspatKF8101_2;