import React from "react";
import Navbar from '../navBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/md-dark-indigo/theme.css";
import "primereact/resources/primereact.min.css"
import 'primeicons/primeicons.css';
import 'react-slideshow-image/dist/styles.css'
import { useTranslation } from "react-i18next";
import FooterComponent from "../footer";
import "../../style/table.css"
import TableFeldspat from "../feldspatTable";
import { Menu } from "primereact/menu";
import FeldspatMenu from "../feldspatMenu";
import "../../style/commonMobile.css";

const FeldspatKF8101 = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <PrimeReactProvider>
            <Navbar />
            <div className="feldspatContainer">
                <div style={{ display: "block" }}>
                    <FeldspatMenu />
                </div>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>
                    <TableFeldspat baslik="TEKNOMADEN FELDSPAR (-212+63 MICRON) 99KF8101 TDS" plantAddress="Yeşilköy/Kavaklıdere/Muğla" kod="99KF8101" humidity="0.3" oilAbsorb="" na2o="8.14" k2o="0.22" cao="0.43" fe2o3="0.10" tio2="0.05" al2o3="17.58" mgo="0.10" p2o5="0.35" sio2="72.73" kk="0.30" l="85.92" a="1.10" b="8.70" albit="66.8" ortoklas="2.3" anortit="2.7" kaolinit="8.0" kuvars="20.2" />
                </div>
            </div>
            <FooterComponent />
        </PrimeReactProvider>
    );
}
export default FeldspatKF8101;