import React from "react"; import Navbar from './navBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PrimeReactProvider } from 'primereact/api';
import "primereact/resources/themes/md-dark-indigo/theme.css";
import "primereact/resources/primereact.min.css"
import 'primeicons/primeicons.css';
import 'react-slideshow-image/dist/styles.css'
import "../style/contactUs.css";
import { useTranslation } from "react-i18next";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import FooterComponent from "./footer";
const ContactCard = props => {
    return (
        <div className="card">
            <i className={props.icon} style={{ fontSize: "2rem" }}></i>
            <h3>{props.heading}</h3>
            <p>{props.value}</p>
            {props.extraNumber ? <Button onClick={() => { window.open("tel://" + props.value.trim()) }} label={props.extraNumber} style={{ background: "#eee", color: "black" }} /> : null}
            {props.extraMail ? <Button onClick={() => { window.open("mailto:" + props.value.trim()) }} label={props.buttonLabel} style={{ background: "#eee", color: "black" }} /> : null}
            {props.extraLocation ? <Button onClick={() => { window.open("https://maps.google.com/maps?q=" + `\"${props.value}\"`) }} label={props.buttonLabel} style={{ background: "#eee", color: "black" }} /> : null}
        </div>)
}

const ContactUs = () => {
    const { t, i18n } = useTranslation();
    return (
        <PrimeReactProvider>
            <Navbar />
            <div className="AboutPage">
                <h1 style={{ fontWeight: "bold" }}>{t('contact')}</h1>
                <div className="contactCards">
                    <ContactCard heading={t("phone")} value="+90 286 416 54 41" extraNumber={t("call")} icon={PrimeIcons.PHONE} />
                    <ContactCard heading={t("mail")} value="info@teknomaden.com.tr" icon={PrimeIcons.ENVELOPE} extraMail buttonLabel={t("sendMail")} />
                    <ContactCard heading={t("location")} value="Atatürk Mahallesi Dr. Hacı İbrahim Bodur Caddesi No 34/1 Çan - Çanakkale" icon={PrimeIcons.MAP_MARKER} extraLocation buttonLabel={t("openLocation")} />
                    <ContactCard heading={t("branch")} value="Kurucuova Mahallesi Mersinli Su Mevki No: 4 Kavaklıdere / Muğla" icon={PrimeIcons.MAP_MARKER} extraLocation buttonLabel={t("openLocation")} />
                </div>
            </div>
            <FooterComponent />
        </PrimeReactProvider>
    );
}
export default ContactUs;