import React from "react";
import { Menu } from "primereact/menu";

const MixFeldspatMenu = props => {
    return (
        <>
            <Menu style={{ borderRadius: 0, background: "white", width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }} model={[
                {
                    label: "MIX FELDSPAR (-10MM)", style: { color: "black", fontWeight: "900", fontSize: "1rem", alignItems: "flex-start" }, items: [
                        { label: <b style={{ color: "black" }}>TEKNOMADEN MIX FELDSPAR (-10MM) 98K8000 TDS</b>, url: "/mixfeldspat", },
                        { separator: true, style: { height: 1, background: "black", width: "100%" } },
                        { label: <b style={{ color: "black" }}>TEKNOMADEN MIX FELDSPAR (-10MM) 98K8902 TDS</b>, url: "/mixfeldspat/98k8902", },
                    ]
                }
            ]} hidden={false} />
            <Menu style={{ borderRadius: 0, background: "white", width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }} model={[
                {
                    label: "MICRONIZED MIX FELDSPAR", style: { color: "black", fontWeight: "900", fontSize: "1rem", alignItems: "flex-start" }, items: [
                        { label: <b style={{ color: "black" }}>TEKNOMADEN MICRONIZED MIX FELDSPAR (-212+63 micron) 98K8201 TDS</b>, url: "/mixfeldspat/98k8201", },

                    ]
                }
            ]} hidden={false} />
        </>
    )
}

export default MixFeldspatMenu;