import React from "react"
import { Slide } from 'react-slideshow-image';
import { Button } from "primereact/button";
import "../style/images.css";
import { PrimeIcons } from "primereact/api";
const image_1 = require("../images/1.jpeg");
const image_2 = require("../images/3.jpeg");
const image_3 = require("../images/6.jpeg");
const image_4 = require("../images/7.jpeg");
const image_5 = require("../images/8.jpeg");
const image_6 = require("../images/sonfoto.jpeg");
const image_7 = require("../images/sonfoto2.jpeg");
const image_8 = require("../images/sonfoto3.jpeg");


const SlideShow = () => {
    const images = [image_1, image_2, image_3, image_4, image_5, image_6, image_7, image_8];

    return (
        <div style={{}}>
            <Slide autoplay={true} infinite duration={3000} prevArrow={<Button icon={PrimeIcons.ARROW_LEFT} style={{ borderRadius: 16, color: "white", width: 50, height: 50, marginLeft: 10 }} />} nextArrow={<Button icon={PrimeIcons.ARROW_RIGHT
            } style={{ borderRadius: 16, color: "white", width: 50, height: 50, marginRight: 10 }} />}>
                <div className="each-slide-effect">
                    <div style={{ backgroundImage: `url(${images[0]})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center center" }}>

                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{ backgroundImage: `url(${images[1]})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center center" }}>

                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{ backgroundImage: `url(${images[2]})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center center" }}>

                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{ backgroundImage: `url(${images[3]})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center center" }}>

                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{ 'backgroundImage': `url(${images[4]})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center center" }}>

                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{ 'backgroundImage': `url(${images[5]})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center center" }}>

                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{ 'backgroundImage': `url(${images[6]})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center center" }}>

                    </div>
                </div>
                <div className="each-slide-effect">
                    <div style={{ 'backgroundImage': `url(${images[7]})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center center" }}>

                    </div>
                </div>
            </Slide >
        </div >
    )
}

export default SlideShow;