import React from "react";
import { Menu } from "primereact/menu";

const FeldspatMenu = props => {
    return (
        <>
            <Menu style={{ borderRadius: 0, background: "white", width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center" }} model={[
                {
                    label: "FELDSPAR (-10MM)", style: { color: "black", fontWeight: "900", fontSize: "1rem", alignItems: "flex-start" }, items: [
                        { label: <b style={{ color: "black" }}>TEKNOMADEN FELDSPAR (-10MM) FK7402 TDS</b>, url: "/feldspat", },
                        { separator: true, style: { height: 1, background: "black", width: "100%" } },
                        { label: <b style={{ color: "black" }}>TEKNOMADEN FELDSPAR (-10MM) FK7502 TDS</b>, url: "/feldspat/fk7502", },
                        { separator: true, style: { height: 1, background: "black", width: "100%" } },
                        { label: <b style={{ color: "black" }}>TEKNOMADEN FELDSPAR (-10MM) FK7602 TDS</b>, url: "/feldspat/fk7602", },
                        { separator: true, style: { height: 1, background: "black", width: "100%" } },
                        { label: <b style={{ color: "black" }}>TEKNOMADEN FELDSPAR (-10MM) FK6103 TDS</b>, url: "/feldspat/fk6103" },
                    ]
                }
            ]} hidden={false} />
            <Menu style={{ borderRadius: 0, background: "white", width: "100%", display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center" }} model={[
                {
                    label: "MICRONIZED FELDSPAR", style: { color: "black", fontWeight: "900", fontSize: "1rem", alignItems: "flex-start" }, items: [
                        { label: <b style={{ color: "black" }}>TEKNOMADEN FELDSPAR (-212+63 micron) 99KF9101</b>, url: "/feldspat/kf9101", },
                        { separator: true, style: { height: 1, background: "black", width: "100%" } },
                        { label: <b style={{ color: "black" }}>TEKNOMADEN FELDSPAR (-212+63 micron) 99KF9201</b>, url: "/feldspat/kf9201", },
                        { separator: true, style: { height: 1, background: "black", width: "100%" } },
                        { label: <b style={{ color: "black" }}>TEKNOMADEN FELDSPAR (-212+63 micron) 98KF9203</b>, url: "/feldspat/kf9203", },
                        { separator: true, style: { height: 1, background: "black", width: "100%" } },
                        { label: <b style={{ color: "black" }}>TEKNOMADEN FELDSPAR (-212+63 micron) 99KF8101</b>, url: "/feldspat/kf8101" },
                        { separator: true, style: { height: 1, background: "black", width: "100%" } },
                        { label: <b style={{ color: "black" }}>TEKNOMADEN FELDSPAR (-212+63 micron) 98KF8101</b>, url: "/feldspat/kf8101_2" },
                        { separator: true, style: { height: 1, background: "black", width: "100%" } },
                        { label: <b style={{ color: "black" }}>TEKNOMADEN FELDSPAR (-212+63 micron) 98KF8203</b>, url: "/feldspat/kf8203" },
                        { separator: true, style: { height: 1, background: "black", width: "100%" } },
                        { label: <b style={{ color: "black" }}>TEKNOMADEN FELDSPAR (-212+63 micron) 99KF7201</b>, url: "/feldspat/kf7201" },
                        { separator: true, style: { height: 1, background: "black", width: "100%" } },
                        { label: <b style={{ color: "black" }}>TEKNOMADEN FELDSPAR (-212+63 micron) 98KF7102</b>, url: "/feldspat/kf7102" },
                        { separator: true, style: { height: 1, background: "black", width: "100%" } },
                        { label: <b style={{ color: "black" }}>TEKNOMADEN FELDSPAR (-212 micron) 99KMEX-KF8101</b>, url: "/feldspat/kmex8101" },
                        { separator: true, style: { height: 1, background: "black", width: "100%" } },
                        { label: <b style={{ color: "black" }}>TEKNOMADEN FELDSPAR (-212 micron) 98KMEX-KF7101</b>, url: "/feldspat/kmex7101" },
                    ]
                }
            ]} hidden={false} />
        </>
    )
}

export default FeldspatMenu